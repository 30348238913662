<script setup>
    import {computed} from 'vue';
    import {usePage} from '@inertiajs/vue3';

    const errors = computed(() => usePage().props.errors);

    const hasErrors = computed(() => Object.keys(errors.value).length > 0);
</script>

<template>
    <div v-if="hasErrors">
        <div class="notification is-danger">
            <div class="content">
                <ul class="mt-3">
                    <li v-for="(error, key) in errors" :key="key">
                        <template v-if="key !== 'email'">
                            {{ error }}
                        </template>

                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
